import React from 'react';
import { Redirect } from 'cccisd-react-router';
import _get from 'lodash/get';

const Fortress = window.cccisd.fortress;

const Resources = () => {
    const userRole = _get(Fortress, 'user.acting.role.handle', '');
    let hasInstructionalVideoValue = _get(Fortress, 'user.acting.role_data.instructionalVideo', -1);
    hasInstructionalVideoValue = hasInstructionalVideoValue !== -1 && userRole === 'instructor';

    const instructionalVideoAlreadyWatched = _get(Fortress, 'user.acting.role_data.instructionalVideo', false);

    return <Redirect to={hasInstructionalVideoValue && instructionalVideoAlreadyWatched ? '/lessons' : '/lessons/v'} />;
};

export default Resources;
