import React, { useState, useContext } from 'react';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';

import GradePicker from '../../components/GradePicker';
import bgBlueK2 from './bgk2-blue.svg';
import bgk2Bubbles from './bgk2-bubbles.svg';
import bgk2BubblesBottom from './bgk2-bubblesBottom.svg';
import bgBlue35 from './bg35-blue.png';
import bgPaint from './bg35-paint.png';
import bg68Split from './bg68-split.png';
import bg68dots from './bg68-dots.svg';
import style from './style.css';
import { LicensingContext } from '../../components/LicensingWrapper';

export const brandK2 = '#00AB68';
export const brand35 = '#11499E';
export const brand68 = '#03328C';
export const brand912 = 'white'; // placeholder

const options = [
    {
        label: 'Grade K',
        value: 'k',
        color: brandK2,
    },
    {
        label: 'Grade 1',
        value: '1',
        color: brandK2,
    },
    {
        label: 'Grade 2',
        value: '2',
        color: brandK2,
    },
    {
        label: 'Grade 3',
        value: '3',
        color: brand35,
    },
    {
        label: 'Grade 4',
        value: '4',
        color: brand35,
    },
    {
        label: 'Grade 5',
        value: '5',
        color: brand35,
    },
    {
        label: 'Grade 6',
        value: '6',
        color: brand68,
    },
    {
        label: 'Grade 7',
        value: '7',
        color: brand68,
    },
    {
        label: 'Grade 8',
        value: '8',
        color: brand68,
    },
    {
        label: 'Grade 9',
        value: '9',
        color: brand912,
    },
    {
        label: 'Grade 10',
        value: '10',
        color: brand912,
    },
    {
        label: 'Grade 11',
        value: '11',
        color: brand912,
    },
    {
        label: 'Grade 12',
        value: '12',
        color: brand912,
    },
];

const Fortress = window.cccisd.fortress;
const pawnId = Fortress.user.acting.id;
const pawnHash = Fortress.user.acting.random_hash;

// use pawnId to scope in case someone has multiple roles, it remembers preferences per role
const localStorageKey = 'cccisd.lionsquest.lessons.' + pawnId;

const Lessons = () => {
    const { licensingData, groupingUnitData } = useContext(LicensingContext);
    if (!licensingData.isValid) {
        return <span />;
    }

    const { deployments, currentTimepoint, showK5, show68, show912 } = groupingUnitData;

    if (!showK5 && !show68 && !show912) {
        return (
            <div className="container">
                <br />
                <div className="alert alert-info">
                    <p>No grade levels are turned on for your District. Please contact administrator.</p>
                </div>
            </div>
        );
    }

    let initialSelected = window.localStorage.getItem(localStorageKey) || '';

    // never set selected to something they can't see
    if (
        !options.map(o => o.value).includes(initialSelected) ||
        (['k', '1', '2', '3', '4', '5'].includes(initialSelected) && !showK5) ||
        (['6', '7', '8'].includes(initialSelected) && !show68) ||
        (['9', '10', '11', '12'].includes(initialSelected) && !show912)
    ) {
        initialSelected = '';
    }

    if (!initialSelected && showK5) {
        initialSelected = 'k';
    } else if (!initialSelected && show68) {
        initialSelected = '6';
    } else if (!initialSelected && show912) {
        initialSelected = '9';
    }

    const [selected, setSelected] = useState(initialSelected || 'k');

    function getThemeVars() {
        if (['k', '1', '2'].includes(selected)) {
            return {
                bgBackdrop: bgBlueK2,
                bgColor: '#2E415B',
                bgFrontdrop: bgk2Bubbles,
                bgFrontdrop2: bgk2BubblesBottom,
                bgFrontdropStyles: {
                    top: '-17px',
                    left: '-25px',
                    width: 'calc(100% + 50px)',
                },
                bgFrontdrop2Styles: {
                    bottom: '-15px',
                    left: '-25px',
                    width: 'calc(100% + 50px)',
                },
                gradePickerColor: brandK2,
            };
        }
        if (['3', '4', '5'].includes(selected)) {
            return {
                bgBackdrop: bgBlue35,
                bgColor: '#2E415B',
                bgFrontdrop: bgPaint,
                bgFrontdropStyles: { right: 0, top: 0 },
                gradePickerColor: brand35,
            };
        }
        if (['6', '7', '8'].includes(selected)) {
            return {
                bgBackdrop: bg68Split,
                bgColor: '#FFFFFF',
                bgFrontdrop: bg68dots,
                bgFrontdropStyles: { left: 0, top: 0 },
                gradePickerColor: brand68,
            };
        }
        return {
            bgBackdrop: bg68Split,
            bgColor: '#2E415B',
            bgFrontdrop: bg68dots,
            bgFrontdropStyles: { left: 0, top: 0 },
            gradePickerColor: brand912,
        };
    }

    const { bgBackdrop, bgColor, bgFrontdrop, bgFrontdrop2, bgFrontdropStyles, bgFrontdrop2Styles, gradePickerColor } =
        getThemeVars();

    let deploymentId = -1;
    try {
        const districtTimepoint = parseInt(currentTimepoint, 10);

        const parentDeploymentHandle = 'dashboard_' + selected;
        const parentDeploymentId = deployments.find(deployment => {
            return deployment.deploymentHandle === parentDeploymentHandle;
        }).deploymentId;

        // find the right child deployment
        deploymentId = deployments.find(deployment => {
            const timepoint = parseInt(deployment.timepoint, 10);
            const isOnCurrentTimepoint = timepoint === districtTimepoint;
            return deployment.parentDeploymentId === parentDeploymentId && isOnCurrentTimepoint;
        }).deploymentId;
    } catch (e) {
        deploymentId = -1;
    }

    return (
        <>
            <div
                className={style.bgBackdrop}
                style={{ backgroundColor: bgColor, backgroundImage: `url(${bgBackdrop})` }}
            >
                {bgFrontdrop && (
                    <img
                        src={bgFrontdrop}
                        className={style.bgFrontdrop}
                        alt="background texture"
                        style={bgFrontdropStyles}
                    />
                )}
                {bgFrontdrop2 && (
                    <img
                        src={bgFrontdrop2}
                        className={style.bgFrontdrop}
                        alt="background texture"
                        style={bgFrontdrop2Styles}
                    />
                )}

                <div className={style.content}>
                    <div className="container">
                        <GradePicker
                            gradePickerColor={gradePickerColor}
                            localStorageKey={localStorageKey}
                            options={options.filter(opt => {
                                if (['k', '1', '2', '3', '4', '5'].includes(opt.value)) {
                                    return showK5;
                                }
                                if (['6', '7', '8'].includes(opt.value)) {
                                    return show68;
                                }
                                if (['9', '10', '11', '12'].includes(opt.value)) {
                                    return show912;
                                }
                                return false;
                            })}
                            selected={selected}
                            setSelected={setSelected}
                        />

                        {selected && (
                            <DeploymentPlayer
                                key={selected}
                                deploymentId={deploymentId}
                                pawnId={pawnId}
                                pawnHash={pawnHash}
                                flowProps={{ isPresenterMode: true }}
                                disableLayout
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Lessons;
