import React from 'react';
import Lessons from '../Lessons';
import VideoModal from '../../components/InstructionalVideoModal';

const LessonsV = props => {
    return (
        <>
            <VideoModal />
            <Lessons />
        </>
    );
};

export default LessonsV;
