import React from 'react';
import { Redirect } from 'cccisd-react-router';

const Fortress = window.cccisd.fortress;
const loggedIn = Fortress.auth();
const isPublicUser = Fortress.user.acting.label === 'Public User' && loggedIn;

const TCKPCommunity = () => {
    if (isPublicUser) {
        return <Redirect to="/tckp/parents" />;
    }

    return <Redirect to="/tckpcommunity/parents" />;
};

export default TCKPCommunity;
