import React from 'react';
import { Route, NotFound } from 'cccisd-laravel-boilerplate';
import defaultProps from 'recompose/defaultProps.js';
import { Switch } from 'react-router-dom';
import { AdminRoutes, Manage as AppdefManage } from 'cccisd-laravel-appdefs';
import { AuthRedirect, Activate } from 'cccisd-laravel-nexus';
import { Quest } from 'cccisd-laravel-assignment';
import Licensing from 'cccisd-laravel-licensing';
import { ResourceCenter } from 'cccisd-laravel-resources';

// Components
import ActivationTermsAndConds from './components/ActivationTermsAndConds';

// Layouts
import MainLayout from './layouts/MainLayout';
import QuestLayout from './layouts/QuestLayout';
import LessonLayout from './layouts/LessonLayout';
import RegisterTCKP from './pages/Register';
import TeacherRegister from './pages/TeacherRegister';

// Pages
/* eslint-disable import/no-extraneous-dependencies, import/no-unresolved */
import Welcome from './pages/Welcome';
import Lessons from './pages/Lessons';
import Resources from './pages/Resources';
import SchoolAdminManage from './pages/SchoolAdminManage';
import ProgressTab from './pages/Progress';
import Community from './pages/Community';
import TeacherRedirect from './pages/TeacherRedirect';
import LessonsV from './pages/LessonsV';

// Laravel packages
import Nexus from 'bundle-loader?lazy!cccisd-laravel-nexus';

// Bind MainLayout by default
const AppRoute = defaultProps({ layout: MainLayout })(Route);

const MainLayoutFluid = defaultProps({ className: 'container-fluid' })(MainLayout);
const MainLayoutSecondaryNav = defaultProps({ showSecondaryNav: true })(MainLayout);
const MainLayoutFluidSecondaryNav = defaultProps({
    className: 'container-fluid',
    showSecondaryNav: true,
})(MainLayout);
const MainLayoutWithBackground = defaultProps({
    isBackground: true,
})(MainLayout);

let Fortress = window.cccisd.fortress;
let AppDefinitions = window.cccisd.appDefs;

const componentMatch = {
    AppdefManage,
    Welcome,
    RegisterTCKP,
    ResourceCenter,
    Lessons,
    Resources,
    SchoolAdminManage,
    ProgressTab,
    Community,
    TeacherRedirect,
    LessonsV,
};

const layoutMatch = {
    MainLayout,
    MainLayoutFluid,
    MainLayoutSecondaryNav,
    MainLayoutFluidSecondaryNav,
    MainLayoutWithBackground,
    QuestLayout,
    LessonLayout,
};

export default () => (
    <Switch>
        {/* Custom text in Activation form */}
        <AppRoute
            path="/account/activate/:activationToken"
            component={Activate}
            layout={MainLayout}
            componentProps={{ renderTermsAndConds: defaults => <ActivationTermsAndConds {...defaults} /> }}
        />

        <AppRoute path="/licensing" component={Licensing} />
        <AppRoute path={['/quest/preview', '/d', '/survey']} component={Quest} layout={QuestLayout} />

        {/* Laravel packages routes */}
        {AdminRoutes(MainLayoutFluid)}

        {AppDefinitions.routes.map(route => {
            const Component = componentMatch[route.componentHandle];
            if (!Component) {
                return null;
            }

            return (
                <AppRoute
                    key={route.handle}
                    path={route.url}
                    component={Component}
                    componentProps={route.componentProps}
                    exact={'exact' in route ? route.exact : true}
                    layout={
                        'layout' in route && layoutMatch[route.layout] ? layoutMatch[route.layout] : MainLayoutFluid
                    }
                />
            );
        })}

        <AppRoute path="/" component={Welcome} exact layout={MainLayoutWithBackground} />
        <AppRoute path="/welcome" component={Welcome} layout={MainLayoutWithBackground} />
        <AppRoute path="/login" component={Welcome} layout={MainLayoutWithBackground} />

        <AppRoute path="/tckp" component={RegisterTCKP} layout={MainLayoutWithBackground} />
        <AppRoute path="/register/:hash" component={TeacherRegister} layout={MainLayoutWithBackground} />
        <AppRoute path="/tckpcommunity" component={Community} />

        <AppRoute
            path="/account"
            layout={null}
            component={Nexus}
            componentProps={{ defaultLayout: MainLayout, registrationSurveyLayout: QuestLayout }}
        />

        <AppRoute path={Fortress.settings.config.after_login_url} component={AuthRedirect} exact />

        {/* Not found page */}
        <AppRoute component={NotFound} />
    </Switch>
);
