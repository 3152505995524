import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Color from 'color';

import DetectClickLocation from 'cccisd-detect-click-location';
import IconDown from 'cccisd-icons/arrow-down20';
import IconCheck from 'cccisd-icons/checkmark4';

import style from './style.css';

function GradePicker({ gradePickerColor, localStorageKey, options, selected, setSelected }) {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    return (
        <div className={style.gradeSelect}>
            <div
                className={style.header}
                style={{
                    backgroundColor: gradePickerColor,
                    color: Color(gradePickerColor).isDark() ? 'white' : 'black',
                }}
            >
                <button className={style.buttonUnstyled} onClick={() => setIsDropdownOpen(prev => !prev)} type="button">
                    <h3>
                        {options.find(opt => opt.value === selected).label}
                        <IconDown spaceLeft />
                    </h3>
                </button>
            </div>
            {isDropdownOpen && (
                <DetectClickLocation clickedOutside={() => setIsDropdownOpen(false)}>
                    <div className={style.dropdownOptions}>
                        {options.map(opt => (
                            <button
                                key={opt.value}
                                type="button"
                                className={style.buttonUnstyled}
                                onClick={() => {
                                    const val = opt.value + '';
                                    if (localStorageKey) {
                                        window.localStorage.setItem(localStorageKey, val);
                                    }
                                    setSelected(val);
                                    setIsDropdownOpen(false);
                                }}
                            >
                                <div className={style.option} style={{ borderColor: opt.color }}>
                                    {opt.value === selected && (
                                        <span className={style.selected}>
                                            <IconCheck spaceRight />
                                        </span>
                                    )}
                                    {opt.label}
                                </div>
                            </button>
                        ))}
                    </div>
                </DetectClickLocation>
            )}
        </div>
    );
}

GradePicker.propTypes = {
    gradePickerColor: PropTypes.string,
    localStorageKey: PropTypes.string,
    options: PropTypes.array,
    selected: PropTypes.string.isRequired,
    setSelected: PropTypes.func.isRequired,
};

GradePicker.defaultProps = {
    gradePickerColor: '#FFFFFF',
    options: [],
};

export default GradePicker;
