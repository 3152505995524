import React from 'react';
import { LoginForm } from 'cccisd-laravel-nexus';
import { Link } from 'cccisd-react-router';
import _find from 'lodash/find';
import Style from './style.css';

var Fortress = window.cccisd.fortress;
var AppDefs = window.cccisd.appDefs;

const Welcome = () => {
    let homepageRoute = _find(AppDefs.routes, r => r.handle === AppDefs.navs[0].homepage);

    return (
        <div className={'row ' + Style.wrapper}>
            <div className="col-12 col-sm-2 col-md-3" />
            <div className="col-12 col-sm-8 col-md-6">
                <div className={Style.content}>
                    {Fortress.auth() && <p>Hello, {Fortress.user.username},</p>}
                    <h2>Welcome to Lions Quest! Let&#39;s get started.</h2>
                    {Fortress.auth() ? (
                        <div>
                            {homepageRoute && (
                                <Link to={homepageRoute.url}>
                                    <button type="button" className="btn btn-primary">
                                        Go To Home
                                    </button>
                                </Link>
                            )}
                        </div>
                    ) : (
                        <LoginForm showPasswordToggle title="" />
                    )}
                </div>
            </div>
            <div className="col-12 col-sm-2 col-md-3" />
        </div>
    );
};

export default Welcome;
