import React, { useState, useEffect } from 'react';
import _get from 'lodash/get';
import axios from 'cccisd-axios';
import Modal from 'cccisd-modal';
import notification from 'cccisd-notification';
import Tooltip from 'cccisd-tooltip';

const Fortress = window.cccisd.fortress;

const ResetDistrict = props => {
    const [districtLabel, setDistrictLabel] = useState('District');
    const [typedText, setTypedText] = useState('');

    useEffect(() => {
        const userDistrictLabel = Fortress.user.acting.group.label;
        setDistrictLabel(userDistrictLabel);
    }, []);

    const Reset = async closeModal => {
        const districtId = Fortress.user.acting.group.id;
        let currentTimepoint = Number(_get(Fortress, 'user.acting.group.type_data.currentTimepoint', 1));
        if (currentTimepoint === 0) {
            currentTimepoint = 1;
        }

        const nextTimePoint = currentTimepoint + 1;
        const route = window.cccisd.boilerplate.route('api.app.updateGroupData', { id: districtId });
        const response = await axios.put(route, { currentTimepoint: nextTimePoint });

        if (response && response.data && response.data.status === 'success') {
            Fortress.user.acting.group.type_data.currentTimepoint = nextTimePoint;
            window.location.reload();
        } else {
            notification({
                message: `Error resetting progress for ${districtLabel} .`,
                data: response && response.data && response.data.errors,
                duration: -1,
                type: 'danger',
            });
        }
        closeModal();
    };

    return (
        <Tooltip title="Reset District Progress">
            <Modal
                trigger={
                    <button type="button" className="btn btn-primary">
                        <span>Reset District Progress</span>
                    </button>
                }
                title={`Are you sure you want to reset progress for ${districtLabel}?`}
                size="medium"
                render={({ closeModal }) => (
                    <>
                        <div>
                            Resetting progress refreshes the status of all lessons to Play for all users. This is
                            typically done at the beginning of a school year.
                        </div>
                        <br />
                        <div>
                            <strong>
                                Type {'"'}Reset{'"'} below to continue:
                            </strong>
                            <div>
                                <input
                                    maxLength={10}
                                    onChange={e => {
                                        setTypedText(e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                        <br />
                        <div style={{ display: 'flex' }}>
                            <button type="button" className="btn btn-secondary" onClick={closeModal}>
                                Cancel
                            </button>
                            <button
                                style={{ marginRight: '0', marginLeft: 'auto' }}
                                type="button"
                                className="btn btn-primary"
                                onClick={() => {
                                    Reset(closeModal);
                                }}
                                disabled={typedText.toLowerCase() !== 'reset'}
                            >
                                Reset Progress
                            </button>
                        </div>
                    </>
                )}
            />
        </Tooltip>
    );
};

export default ResetDistrict;
