import React from 'react';

import IconCopy from 'cccisd-icons/copy3';
import { Manage as AppdefManage } from 'cccisd-laravel-appdefs';
import Tooltip from 'cccisd-tooltip';
import notification from 'cccisd-notification';
import { useLocation } from 'cccisd-react-router';

const Boilerplate = window.cccisd.boilerplate;
const Fortress = window.cccisd.fortress;

const Manage = props => {
    const { hash: selectedTab } = useLocation();
    const hash = Fortress.user.acting?.group?.random_hash;
    const link = Boilerplate.url('/register/' + hash);

    function copyLink() {
        navigator.clipboard.writeText(link);
        notification({ message: 'Link copied to clipboard!', type: 'success' });
    }

    function renderGroupRegistrationLink() {
        if (!hash || selectedTab !== '#instructor') {
            return;
        }

        return (
            <div className="alert alert-info">
                Add <b>Teachers</b> using the form below. Alternatively, they can register themselves at{' '}
                <Tooltip title="Copy to clipboard">
                    <a
                        href="#"
                        onClick={e => {
                            e.preventDefault();
                            copyLink();
                        }}
                    >
                        {link}
                    </a>
                </Tooltip>
                &nbsp;&nbsp;
                <Tooltip title="Copy to clipboard">
                    <button className="btn btn-info btn-xs" onClick={copyLink} type="button">
                        <IconCopy />
                    </button>
                </Tooltip>
            </div>
        );
    }

    return (
        <div>
            <h1>Manage</h1>
            {renderGroupRegistrationLink()}
            <AppdefManage {...props} pageTitle="" />
        </div>
    );
};

export default Manage;
