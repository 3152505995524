import React from 'react';
import PropTypes from 'prop-types';
import Footer from 'cccisd-footer';
import style from './style.css';
import NavBar from '../../components/NavBar';

export default class MainLayout extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        className: PropTypes.string,
        isBackground: PropTypes.bool,
    };

    static defaultProps = {
        className: 'container',
        isBackground: false,
    };

    render() {
        return (
            <div className={`${style.wrapper} ${this.props.isBackground ? style.hasBackground : ''}`}>
                <div className={style.header}>
                    <NavBar className={this.props.className} />
                </div>
                <div className={style.body}>
                    <div className={this.props.className}>{this.props.children}</div>
                </div>

                <div className={style.footer}>
                    <Footer className={this.props.className} />
                </div>
            </div>
        );
    }
}
