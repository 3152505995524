import React, { useRef, useEffect, useContext } from 'react';
import Modal from 'cccisd-modal';
import { ProducerPlayer } from 'cccisd-video-player';
import _get from 'lodash/get';
import axios from 'cccisd-axios';
import notification from 'cccisd-notification';
import { LicensingContext } from '../LicensingWrapper';

const Fortress = window.cccisd.fortress;

const VideoModal = () => {
    const { licensingData } = useContext(LicensingContext);
    if (!licensingData.isValid) {
        return <span />;
    }

    const autoplayVideoModalTriggerRef = useRef(null);
    const instructionalVideoAlreadyWatched = _get(Fortress, 'user.acting.role_data.instructionalVideo', false);
    const hasInstructionalVideoValue = _get(Fortress, 'user.acting.role_data.instructionalVideo', -1);
    const userRole = _get(Fortress, 'user.acting.role.handle', '');

    useEffect(() => {
        // autoplay the instructional video if this is the user's first login, or if they haven't completed the video yet
        if (hasInstructionalVideoValue !== -1 && userRole === 'instructor') {
            if (!instructionalVideoAlreadyWatched) {
                autoplayVideoModalTriggerRef.current.open();
            }
        }
    }, []);

    async function onInstructionalVideoEnded() {
        if (hasInstructionalVideoValue !== -1) {
            const pawnId = Fortress.user.acting.id;
            const randomHash = Fortress.user.acting.random_hash;
            const route = window.cccisd.boilerplate.route('api.app.updatePawnData', {
                id: pawnId,
                random_hash: randomHash,
            });
            const response = await axios.put(route, { instructionalVideo: true });

            Fortress.user.acting.role_data.instructionalVideo = true;
            if (response.status !== 200) {
                notification({
                    message: 'Unable to update video progress. Error code: ' + response.status,
                    type: 'danger',
                });
            }
        }
    }

    const licensedUserVideoModal = (
        <Modal
            title="Welcome!"
            size="large"
            ref={autoplayVideoModalTriggerRef}
            render={() => <ProducerPlayer id={17840} autoplay onEnded={onInstructionalVideoEnded} />}
        />
    );

    return <>{licensedUserVideoModal}</>;
};

export default VideoModal;
