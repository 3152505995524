import React from 'react';
import { RegisterLoginPage } from 'cccisd-laravel-nexus';

const RegisterTCKP = () => {
    const registerLoginProps = {
        registerProps: {
            role: 'learner',
            showPasswordFields: true,
            validateAdditionalFields: values => {
                let errors = {};

                if (!values.first_name) {
                    errors.first_name = 'First name is required.';
                }
                if (!values.last_name) {
                    errors.last_name = 'Last name is required.';
                }

                return errors;
            },
        },
    };
    return (
        <>
            <div style={{ color: '#495879', padding: '40px', display: 'flex', justifyContent: 'center' }}>
                <h2>Welcome to Lions Quest! Let&#39;s get started.</h2>
            </div>
            <RegisterLoginPage {...registerLoginProps} />
        </>
    );
};

export default RegisterTCKP;
