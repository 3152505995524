import React from 'react';
import { domOnlyProps, FieldBlock } from 'cccisd-redux-form-helpers';
import style from './style.css';

const ActivationTermsAndConds = ({ cccisdPrivacyPolicyUrl, cccisdTermsOfUseUrl, field }) => {
    return (
        <div className="card" style={{ marginBottom: '1em' }}>
            <div className="card-header">
                <span style={{ fontSize: '20px', fontWeight: 'bold' }}>3C Privacy Policy and Terms of Use</span>
            </div>
            <div className="card-body">
                <p>Lions Quest uses the 3C Platform</p>
                <div className={style.terms}>
                    <ul>
                        <li>
                            <a href={cccisdPrivacyPolicyUrl} target="blank">
                                Privacy Policy
                            </a>
                        </li>
                        <li>
                            <a href={cccisdTermsOfUseUrl} target="blank">
                                Terms of Use
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="card-footer">
                <FieldBlock field={field}>
                    <div className="checkbox form-check">
                        <input type="checkbox" className="form-check-input" {...domOnlyProps(field)} />
                        <label className="form-check-label">I accept the privacy policy and terms of use.</label>
                    </div>
                </FieldBlock>
            </div>
        </div>
    );
};

export default ActivationTermsAndConds;
