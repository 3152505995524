import React, { useContext } from 'react';
import { LicensingContext } from '../../components/LicensingWrapper';
import { Progress } from 'cccisd-laravel-assignment';

const Fortress = window.cccisd.fortress;

const ProgressTab = () => {
    const isUber = Fortress.user.acting.role.handle === 'uberadmin';
    const { licensingData, groupingUnitData } = useContext(LicensingContext);
    if (licensingData.isLoading || !groupingUnitData.currentTimepoint) {
        return <span />;
    }

    const currentTimepoint = groupingUnitData.currentTimepoint;
    const districtTimepoint = parseInt(currentTimepoint, 10);

    const allParentDashboardDeployments = groupingUnitData.deployments.filter(deployment => {
        return deployment.deploymentHandle && deployment.deploymentHandle.startsWith('dashboard_');
    });
    const assignmentIdWhiteList = allParentDashboardDeployments.map(deployment =>
        parseInt(deployment.assignment.assignmentId, 10)
    );
    const parentDeploymentIds = allParentDashboardDeployments.map(deployment => {
        return deployment.deploymentId;
    });
    const allLatestDashboardChildDeployments = groupingUnitData.deployments.filter(deployment => {
        return (
            parentDeploymentIds.includes(deployment.parentDeploymentId) &&
            // uberadmin see all timepoints, other roles see only their current timepoint
            (parseInt(deployment.timepoint, 10) === districtTimepoint || (isUber && deployment.timepoint))
        );
    });

    const latestDashboardChildDeploymentIds = allLatestDashboardChildDeployments.map(d => {
        return d.deploymentId;
    });

    const projectId =
        allLatestDashboardChildDeployments.length > 0 ? allLatestDashboardChildDeployments[0].assignment.groupId : 1;

    return (
        <>
            <Progress
                projectId={projectId}
                hideProjectDropdown
                hideDeploymentDropdown={!isUber}
                labelForQuest="Grade"
                labelForDeployment="Timepoint"
                labelForShowByAssignment="Grade"
                labelForShowByModule="Unit"
                labelForShowBySurvey="Lesson"
                deploymentIdWhitelist={latestDashboardChildDeploymentIds}
                assignmentIdWhitelist={assignmentIdWhiteList}
            />
        </>
    );
};

export default ProgressTab;
