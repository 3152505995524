import React from 'react';
import Copy from 'cccisd-icons/copy3';
import style from './style.css';
import Notification from 'cccisd-notification';
import Tooltip from 'cccisd-tooltip';

const Boilerplate = window.cccisd.boilerplate;

export default settings => {
    return ({ value }) => {
        const URL = Boilerplate.url(`/register/${value}`);

        const copyToClipboard = () => {
            navigator.clipboard.writeText(URL);
            Notification({ message: 'Copied link to clipboard!', type: 'success' });
        };

        return (
            <div className={style.cell} key={value}>
                {value && (
                    <>
                        <Tooltip title="Copy to clipboard">
                            <a onClick={copyToClipboard}>/{value}</a>
                        </Tooltip>
                        <Tooltip title="Copy to clipboard">
                            <button onClick={copyToClipboard} type="button" className={style.icon}>
                                <Copy />
                            </button>
                        </Tooltip>
                    </>
                )}
            </div>
        );
    };
};
