import React from 'react';
import PropTypes from 'prop-types';
import Header from 'cccisd-header';
import style from './style.css';
import { useLocation } from 'cccisd-react-router';

const Appdefs = window.cccisd.appDefs;
const Fortress = window.cccisd.fortress;

const NavBar = ({ className, noLogoLink }) => {
    let primaryNavLinks = [];

    let location = useLocation();
    const loggedIn = Fortress.auth();
    const inCommunityRoute = location.pathname.includes('/tckpcommunity');
    const inLoginPage = !loggedIn && (location.pathname === '/' || location.pathname === '/login');
    let communityTabs = ['Parents', 'Kids'];

    const isPublicUser = Fortress.user.acting.label === 'Public User' && loggedIn;
    const isCommunityUser = inCommunityRoute && !loggedIn;
    const logoStyle = isCommunityUser ? style.logoNoClick : style.logoPlaceholder;
    const loggedInCommunity = inCommunityRoute && loggedIn;

    let additionalProps = noLogoLink;
    // remove login button & override logo link for community users
    if (isCommunityUser) {
        additionalProps = { logoLink: '/tckpcommunity', showUserNav: false };
    } else if (loggedInCommunity || inLoginPage) {
        additionalProps = { showUserNav: false };
    } else {
        additionalProps = noLogoLink ? { logoLink: null } : {};
    }

    try {
        Appdefs.navs.forEach(element => {
            let filteredNavs = element.nav;

            // always hide public tabs if not in /tckpcommunity route
            if (!inCommunityRoute && !isPublicUser) {
                filteredNavs = filteredNavs.filter(nav => {
                    return !communityTabs.includes(nav.label);
                });
                // only show when in /tckpcommunity route, OR if it is a logged in public user
            } else if (!isPublicUser && inCommunityRoute) {
                filteredNavs = filteredNavs.filter(nav => {
                    return communityTabs.includes(nav.label);
                });
            }
            primaryNavLinks.push(...filteredNavs);
        });
    } catch (e) {
        // meh, leave it empty
    }

    return (
        <Header
            containerClassName={className}
            logoClassName={logoStyle}
            primaryNavLinks={primaryNavLinks}
            showAlertAction={false}
            showHomeAction={false}
            showAdminAction={Fortress.user.acting.role.handle === 'uberadmin' && !inCommunityRoute}
            {...additionalProps}
        />
    );
};

NavBar.defaultProps = {
    className: 'container',
    noLogoLink: false,
};

NavBar.propTypes = {
    className: PropTypes.string,
    noLogoLink: PropTypes.bool,
};

export default NavBar;
