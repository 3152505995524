import React from 'react';
import _get from 'lodash/get';
import { isPast, parse } from 'date-fns';
import IconWarning from 'cccisd-icons/warning';
import style from './style.css';

export default settings => {
    return ({ value }) => {
        const heldLicense = Array.isArray(value) && value.length > 0 ? value[0] : false;
        const expirationSetting = _get(heldLicense, 'settings.defaultExpirationSelection', 'duration');
        const expirationDate = _get(heldLicense, 'settings.defaultExpirationValueDate');
        const expirationDuration = _get(heldLicense, 'settings.defaultExpirationValueMonths', 12);

        if (expirationSetting === 'date' && expirationDate) {
            const isExpired = isPast(parse(expirationDate));
            return (
                <span className={style.minWidth}>
                    {expirationDate}
                    {isExpired && (
                        <div className="text-warning">
                            <b>
                                <IconWarning spaceRight />
                                Expired
                            </b>
                        </div>
                    )}
                </span>
            );
        }

        return <span className={style.minWidth}>{expirationDuration} months</span>;
    };
};
